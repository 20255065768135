import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { LanguagesList } from 'components/Languages/LanguagesList';
import { useMediaQuery } from '@chakra-ui/react';
// eslint-disable-next-line react/prop-types
const LanguageSelector = ({ onLanguageChange }) => {
  const getCountryCodeForLanguage = languageCode => {
    const language = LanguagesList.find(l => l.languageCode === languageCode);
    if (language) {
      return language.countryCode;
    } else {
      console.error(`Language code ${languageCode} not found in LanguagesList.`);
      return null;
    }
  };
  const [selectedCountry, setSelectedCountry] = useState(() => {
    // Initialize state with the country code corresponding to the language stored in localStorage
    const defaultLanguage = localStorage.getItem('language') || 'en';
    return getCountryCodeForLanguage(defaultLanguage);
  });
  const isMobile = useMediaQuery(['(max-width: 800px)'])[0];
  useEffect(() => {
    const defaultLanguage = localStorage.getItem('language') || 'en';
    const countryCode = getCountryCodeForLanguage(defaultLanguage);
    if (countryCode && selectedCountry !== countryCode) {
      setSelectedCountry(countryCode);
    }
  }, [selectedCountry]);

  const getCountryCodeArray = () => {
    return LanguagesList.map(l => l.countryCode);
  };

  const getCustomLabels = () => {
    const labels = {};
    LanguagesList.forEach(l => {
      labels[l.countryCode] = l.language;
    });
    return labels;
  };

  const onCountryChanged = countryCode => {
    setSelectedCountry(countryCode);
    const languageCode = getLanguageCodeForCountry(countryCode);
    if (languageCode) {
      onLanguageChange(languageCode);
      localStorage.setItem('language', languageCode);
    }
  };

  const getLanguageCodeForCountry = countryCode => {
    const language = LanguagesList.find(l => l.countryCode === countryCode);
    return language ? language.languageCode : null;
  };

  return (
    <ReactFlagsSelect
      countries={getCountryCodeArray()}
      customLabels={getCustomLabels()}
      selected={selectedCountry}
      onSelect={onCountryChanged}
      placeholder="Select Language"
      selectedSize={isMobile ? 12 : 16}
      optionsSize={isMobile ? 12 : 16}
    />
  );
};

export default LanguageSelector;
