import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout/index';

const Construction = Loadable(lazy(() => import('views/Construction')));
const Workshops = Loadable(lazy(() => import('views/Workshops')));
const SignOut = Loadable(lazy(() => import('pages/authentication/SignOut')));
const Facilitator = Loadable(lazy(() => import('views/Facilitators')));
const WorkshopDetails = Loadable(lazy(() => import('views/WorkshopDetails')));
const Exercises = Loadable(lazy(() => import('views/Exercises')));
const Exercise = Loadable(lazy(() => import('views/Exercise')));
const MonitorSubmissions = Loadable(lazy(() => import('views/MonitorSubmissions')));
const FinalResults = Loadable(lazy(() => import('views/FinalResults')));
const Settings = Loadable(lazy(() => import('views/ChangePassword')));
const NotFound = Loadable(lazy(() => import('views/NotFound')));
const Participants = Loadable(lazy(() => import('views/Participants')));
const ParticipantView = Loadable(lazy(() => import('views/ParticipantView')));
const ParticipantSignIn = Loadable(lazy(() => import('views/ParticipantSignIn')));
const ForgotPassword = Loadable(lazy(() => import('views/ForgotPassword')));
const FacilitatorSignIn = Loadable(lazy(() => import('views/FacilitatorSignIn')));
const FirstTimeLogin = Loadable(lazy(() => import('views/FirstTimeLogin')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: 'workshops',
      element: (
        <MainLayout>
          <Workshops />
        </MainLayout>
      )
    },
    {
      path: '/',
      element: (
        <MainLayout>
          {' '}
          <Workshops />,
        </MainLayout>
      ),
      default: true // Changed `default` to `index` to mark the default route
    },
    {
      path: 'workshop/:workshopCode',
      element: (
        <MainLayout>
          <WorkshopDetails />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/final-results',
      element: (
        <MainLayout>
          {' '}
          <FinalResults />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/monitor-submissions',
      element: (
        <MainLayout>
          <MonitorSubmissions />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/exercises',
      element: (
        <MainLayout>
          {' '}
          <Exercises />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/participants',
      element: (
        <MainLayout>
          {' '}
          <Participants />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/exercises/business-imperative-prioritization',
      element: (
        <MainLayout>
          <Exercises />
        </MainLayout>
      )
    },
    {
      path: 'workshop/:workshopCode/exercises/:exercise',
      element: (
        <MainLayout>
          <Exercise />
        </MainLayout>
      )
    },
    {
      path: 'facilitators',
      element: (
        <MainLayout>
          {' '}
          <Facilitator />
        </MainLayout>
      )
    },
    {
      path: 'settings',
      element: (
        <MainLayout>
          <Settings />
        </MainLayout>
      )
    },
    {
      path: 'sign-out',
      element: <SignOut />
    },
    {
      path: 'home',
      element: (
        <MainLayout>
          {' '}
          <Construction />
        </MainLayout>
      )
    },
    {
      path: '*',
      element: (
        <MainLayout>
          <NotFound />
        </MainLayout>
      )
    },
    {
      path: 'participant/:workshopCode',
      element: <ParticipantSignIn />
    },
    {
      path: 'participant/:workshopCode/:participantName',
      element: <ParticipantView />
    },
    {
      path: 'participant',
      element: <ParticipantSignIn />
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />
    },
    {
      path: 'first-time-login/:participantEmail/RVAe4M2GKyNjdv9XUY63Fzg',
      element: <FirstTimeLogin />
    },
    {
      path: 'login',
      element: <FacilitatorSignIn />
    }
  ]
};

export default MainRoutes;
