// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ChakraProvider } from '@chakra-ui/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <I18nextProvider i18n={i18n}>
    <ChakraProvider>
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </ChakraProvider>
  </I18nextProvider>
);

export default App;
